<template>

  <nav class="navbar">
    <div class="container-fluid">
      <a class="navbar-brand" @click="this.$router.push('/')  ">
        <img class="img-fluid" src="../assets/logo.svg" alt="">
      </a>
      <div class="d-flex">
        <a class="a mt-2 me-1" role="button" @click="doAccount">
          <svg class="account-svg bi bi-person-fill" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
               viewBox="0 0 16 16">
            <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
          </svg>
          My Account
        </a>
      </div>
    </div>
  </nav>

  <div class="main-height">
    <section class="change-password">
      <div class="container">
        <div class="inner-container">
          <div class="row">
            <div class="col-12">
              <div class="fw-bold">
                <h1 class="fw-bold">
                  Change Password
                </h1>
              </div>
              <div>
                <div class="register-form">
                  <div class="input-group mt-2">
                    <input v-model="newPassword" required="" type="password" name="password" autocomplete="off" class="input">
                    <label class="user-label">New Password</label>
                  </div>
                  <div class="input-group mt-2">
                    <input v-model="confirmPassword" required="" type="password" name="password" autocomplete="off" class="input" @keyup.enter="doSubmit">
                    <label class="user-label">Retype New Password</label>
                  </div>
                </div>
                <div v-if="error.length" class="alert-danger mt-3 text-danger">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                    class="bi bi-exclamation-circle me-1 mb-1" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    <path
                      d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
                  </svg>
                  {{ error }}
                </div>
                <div class="changepass-checkbox mt-2">
                  <div class="d-flex">
                    <input class="form-check-input check-btn rounded-0" type="checkbox" value="" id="flexCheckDefault"
                      v-model="checked" />
                    <label class="form-check-label ms-1" for="flexCheckDefault">Logout from all devices.</label>
                  </div>
                  
                </div>
                <button class="btn btn-primary w-100 mt-3" @click="doSubmit">
                  <span v-show="!loading">Save</span>
                  <div v-show="loading" class="button-loading">
                    <div class="spinner-border" role="status">
                      <span class="sr-only"></span>
                    </div>
                  </div>
                </button>
              </div>
                <button class="btn w-100 mt-2 cancel-btn" @click="doCancel">Cancel</button>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  
  <Footer></Footer>

</template>

<script>
import {defineComponent} from 'vue'
import UsersService from "@/services/users-service";
import Footer from "@/components/Footer.vue";
import {PasswordRequest} from "@/model/models";

export default defineComponent({
  name: "Change Password",
  components : {
    Footer
  },  
  data() {
    return {
      newPassword: '',
      confirmPassword: '',
      checked: '',
      error: '',
      loading: false
    }
  },
  methods: {
    
    doAccount() {
      this.$router.push('/account')
    },
    doCancel: function () {
      this.$router.push('/account')
    },
    doSubmit: function () {
      if (this.loading) return;
      this.loading = true;
      if (this.newPassword == '' || this.confirmPassword == '') {
        this.error = "All fields are required.";
        this.loading = false;
      } else if (this.newPassword.length < 6) {
        this.error = 'Password must have at least 6 characters.';
        this.loading = false;
      } else if (this.newPassword != this.confirmPassword) {
        this.error = "New Password fields do not match. Please re-enter.";
        this.loading = false;
      } else {
        UsersService.changePasswordRequest(new PasswordRequest("oldPassword", this.newPassword)).then((valid) => {
          if (valid) {
            if (this.checked == '') {
              localStorage.setItem('password', "true")
              this.$router.push('/account')
            } else {
              // log out from all devices
              UsersService.logoutFromAllDevices().then((valid) => {
                if (valid) {
                  localStorage.setItem('password', "true")
                  this.$router.push('/account')
                } else {
                  this.error = "There was an error while submitting password. Try again later.";
                  this.loading = false;
                }
              })
            }
          } else {
            this.error = "There was an error while submitting password. Try again later.";
            this.loading = false;
          }
        })
      }
    }
  }
})
</script>

<style>
@import '../assets/style.css';
</style>
